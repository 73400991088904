import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import imageBuilder from '../../utils/imageBuilder';

import SideSection from './SideSection';
import ImageAndTextBox from './ImageAndTextBox';
import VideoModule from './VideoModule';
import ImageTextModule from './ImageTextModule';
import QuoteModule from './QuoteModule';
import EventList from './EventList';
import TitleDivider from './TitleDivider';
import Columns from './Columns';
import VenueList from './VenueList';
import LargeImageWithText from './LargeImageWithText';
import Spacer from './Spacer';
import IframeContainer from './IframeContainer';
import ContactForm from './ContactForm';
import FactsList from './FactsList';
import PageHeroTakeOver from './PageHeroTakeOver';
import CTAButton from './CTAButton';
import CalloutWithMedia from './CalloutWithMedia';
import MediaWithSizeOption from './MediaWithSizeOption';
import PageTitle from './PageTitle';
import VenueDetailsWithPortableText from './VenueDetailsWithPortableText';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .module {
        margin-bottom: 50px;

        @media (max-width: 768px) {
            margin-bottom: 35px;
        }
    }
`;

const ModuleList = ({ modules }) => {
    if (!modules || !modules.length) return null;

    return (
        <Container>
            {modules.map((module, index) => {
                switch (module._type) {
                    case 'calloutWithMedia':
                        return (
                            <CalloutWithMedia
                                key={module._key}
                                orientation={module.orientation}
                                color={module.color}
                                title={module.title}
                                media_image={
                                    module.media &&
                                    module.media[0]._type !== 'video' &&
                                    imageBuilder(module.media[0])
                                        .url()
                                        .toString()
                                }
                                video={
                                    module.media &&
                                    module.media[0]._type === 'video' &&
                                    module.media[0]
                                }
                                media={module.media}
                            />
                        );
                    case 'ctaButton':
                        return (
                            <CTAButton
                                key={module._key}
                                buttons={module.buttonList}
                            />
                        );
                    case 'contactForm':
                        return (
                            <ContactForm key={module._key} module={module} />
                        );

                    case 'factsList':
                        return <FactsList key={module._key} module={module} />;
                    case 'spacer':
                        return (
                            <Spacer key={module._key} space={module.space} />
                        );
                    case 'sideSection':
                        return (
                            <SideSection
                                key={module._key}
                                title={module.title}
                                columns={module.columns}
                                slug={module.slug}
                            />
                        );
                    case 'pageTitle':
                        return (
                            <PageTitle
                                key={module._key}
                                title={module.title}
                                color={module.color}
                                isFilled={module.isFilled}
                                isPageTitle={module.isPageTitle}
                                size={module.size}
                                sectionId={module.sectionId}
                            />
                        );
                    case 'mediaWithSizeOption':
                        return (
                            <MediaWithSizeOption
                                key={module._key}
                                media_image={
                                    module.media &&
                                    module.media[0]._type !== 'video' &&
                                    imageBuilder(module.media[0])
                                        .url()
                                        .toString()
                                }
                                video={
                                    module.media &&
                                    module.media[0]._type === 'video' &&
                                    module.media[0]
                                }
                                size={module.size}
                                offset={module.offset}
                            />
                        );
                    case 'imageAndTextBox':
                        return (
                            <ImageAndTextBox
                                key={module._key}
                                media_image={
                                    module.media &&
                                    module.media[0]._type !== 'video' &&
                                    imageBuilder(module.media[0])
                                        .url()
                                        .toString()
                                }
                                video={
                                    module.media &&
                                    module.media[0]._type === 'video' &&
                                    module.media[0]
                                }
                                media={module.media}
                                text={
                                    module.text && (
                                        <BlockContent blocks={module.text} />
                                    )
                                }
                                reversed={module.reversed}
                                backgroundColor={module.backgroundColor}
                                sectionId={module.sectionId}
                            />
                        );
                    case 'videoModule':
                        return (
                            <VideoModule
                                videoLink={module.url}
                                mobileVideoLink={module.mobile_url}
                                autoplay={module.autoplay}
                                key={module._key}
                                pullup={index === 0}
                            />
                        );
                    case 'venueList':
                        return (
                            <VenueList
                                key={module._key}
                                venues={module.venues}
                            />
                        );
                    case 'venueDetails':
                        return (
                            <VenueDetailsWithPortableText
                                key={module._key}
                                openingTimes={module.openingTimes}
                                facebookLink={module.facebookLink}
                                instagramLink={module.instagramLink}
                                telephone={module.telephone}
                                email={module.email}
                                ageLimit={module.ageLimit}
                                absolute={module.absolute}
                                content={
                                    module.content && (
                                        <BlockContent blocks={module.content} />
                                    )
                                }
                            />
                        );
                    case 'imageAndText':
                        return (
                            <ImageTextModule
                                title={module.title}
                                text={
                                    module.text && (
                                        <BlockContent blocks={module.text} />
                                    )
                                }
                                video={module.video}
                                link={module.link}
                                key={module._key}
                                images={
                                    module.images &&
                                    module.images.map(image =>
                                        imageBuilder(image)
                                    )
                                }
                                mirrored={module.layout === 'text-image'}
                            />
                        );
                    case 'largeImageWithText':
                        return (
                            <LargeImageWithText
                                key={module._key}
                                text={module.text}
                                image={
                                    module.image &&
                                    imageBuilder(module.image)
                                        .url()
                                        .toString()
                                }
                            />
                        );
                    case 'quote':
                        return (
                            <QuoteModule
                                title={module.title}
                                quote={module.quote}
                                meta={
                                    module.meta && (
                                        <BlockContent blocks={module.meta} />
                                    )
                                }
                                key={module._key}
                                image={
                                    module.image && imageBuilder(module.image)
                                }
                                reversed={module.layout === 'reversed'}
                            />
                        );
                    case 'eventList':
                        return <EventList key={module._key} />;
                    case 'titleDivider':
                        return (
                            <TitleDivider
                                key={module._key}
                                title={module.title}
                                text={module.text}
                            />
                        );
                    case 'columns':
                        return (
                            <Columns
                                key={module._key}
                                columns={module.columns}
                            />
                        );
                    case 'iframe':
                        return <IframeContainer {...module} />;
                    case 'pageHeroTakeOver':
                        return (
                            <PageHeroTakeOver
                                media_image={
                                    module.media &&
                                    module.media[0]._type !== 'video' &&
                                    imageBuilder(module.media[0])
                                        .url()
                                        .toString()
                                }
                                video={
                                    module.media &&
                                    module.media[0]._type === 'video' &&
                                    module.media[0]
                                }
                                media={module.media}
                                {...module}
                            />
                        );
                    default:
                        return <div>Module not found</div>;
                }
            })}
        </Container>
    );
};

export default ModuleList;
