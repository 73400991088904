import React from 'react';
import styled from 'styled-components';
import SocialButton from '../buttons/SocialButton';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    display: flex;
    gap: 144px;
    font-weight: 500;
    align-self: flex-start;
    transform: translateY(150px);

    ${props =>
        props.isVisible &&
        `
      transform: translateY(0);
        transition: transform 0.5s ease-out;
      `}

    overflow-wrap: break-word;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        position: initial;
        max-width: none;
        width: 100%;
        margin-bottom: 0;
        gap: 60px;

        .social-links {
            display: flex;
            flex-direction: row;

            > * {
                margin-right: 20px;
            }
        }
    }

    h1,
    h2,
    h3,
    h4 {
        margin-block-start: 0;
        margin-block-end: 0;
        line-height: 1;
        margin-bottom: 36px;
    }

    p {
        margin-bottom: 0;
        margin-top: 0;
    }

    .time {
        white-space: pre-wrap;
    }

    .social-links > * {
        margin-bottom: 10px;
    }
    .right {
        h3,
        h4 {
            font-size: 32px;
            font-weight: 500;
        }
        p {
            font-size: 24px;
        }

        @media (min-width: 768px) {
            max-width: 66%;
            h3,
            h4 {
                font-size: 32px;
            }
        }
    }
`;

const VenueDetailsWithPortableText = ({
    content,
    ageLimit,
    facebookLink,
    instagramLink,
    telephone,
    email,
    absolute,
    ...rest
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            className="venue-info"
            isVisible={isVisible}
            ref={ref}
            {...rest}
        >
            <div className="left">
                <h2>Details</h2>

                {(!!facebookLink || !!instagramLink) && (
                    <div className="social-links">
                        {!!facebookLink && (
                            <SocialButton type="facebook" link={facebookLink} />
                        )}

                        {!!instagramLink && (
                            <SocialButton
                                type="Instagram"
                                link={instagramLink}
                            />
                        )}
                    </div>
                )}
                <br />
                {(!!telephone || !!email) && (
                    <div className="contact-info">
                        {!!telephone && <p>{telephone}</p>}
                        {!!email && <p>{email}</p>}
                    </div>
                )}
                <br />
                {!!ageLimit && (
                    <p className="ageLimit">Age limit: {ageLimit}</p>
                )}
            </div>
            {!!content && <div className="right">{content}</div>}
        </Container>
    );
};

export default VenueDetailsWithPortableText;
