import { takeEvery, put, select } from 'redux-saga/effects';
import Api from '../../services/Api';
import stripSlug from '../../utils/stripSlug';

const api = new Api();

function* fetchSlug(action) {
    try {
        const slug = stripSlug(action.payload);
        // Check if we have the page cached in redux
        let page = yield select(state => state.pages[slug]);

        // If we have the page we dont do anything
        if (page) {
            yield put({
                type: 'page/found-in-cache',
                page
            });
            return;
        }

        // Check if it's an event, venue or stavkroa arena

        let pageSlug = slug;
        let type = 'page';

        if (slug.indexOf('/')) {
            let split = slug.split('/');
            pageSlug = split[split.length - 1];
            if (split[0] === 'event') type = 'event';
            if (split[0] === 'venue' && split[1] !== 'stavkroa-arena')
                type = 'venue';
            if (split[0] === 'venue' && split[1] === 'stavkroa-arena') {
                type = 'stavkroaArenaSingleton';
                pageSlug = split[1];
            }
        }

        // Check if we need to fetch the globals as well
        const { getGlobals, fetchEvents } = yield select(state => ({
            getGlobals: !state.global.hasGlobals,
            fetchEvents: !state.events.length
        }));

        const response = yield api.getSlug(
            pageSlug,
            type,
            getGlobals,
            fetchEvents
        );

        console.log('RESPONSE FETCH EVENTS: ', response);

        if (getGlobals && response && response.globals) {
            yield put({
                type: 'global/set',
                payload: response.globals
            });
        }

        if (response && response.page) {
            yield put({
                type: 'page/set',
                payload: {
                    slug,
                    data: response.page
                }
            });

            if (
                response.page.modules &&
                response.page.modules.find(a => a._type === 'eventList')
            ) {
                yield put({
                    type: 'events/set',
                    payload: response.page.modules
                        .find(a => a._type === 'eventList')
                        .events.filter(x => !x._id.includes('draft'))
                });
            }
        } else {
            yield put({
                type: 'page/not-found'
            });
        }
    } catch (e) {
        yield put({
            type: 'page/not-found'
        });
    }
}

export default [takeEvery('page/get-by-slug', fetchSlug)];
