import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const DEFAULT_SAMPLE_RATE = 0.3;

export const initializeSentry = () => {
    if (!process.env.REACT_APP_SENTRY_DSN) {
        return;
    }

    init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLERATE
            ? parseInt(process.env.REACT_APP_SENTRY_SAMPLERATE)
            : DEFAULT_SAMPLE_RATE
    });
};
