import React from 'react';
import styled from 'styled-components';
import ModuleList from '../components/modules/ModuleList';
import { connect } from 'react-redux';
import useSlug from '../hooks/useSlug';

const Container = styled.div`
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 768px) {
        padding-left: 60px;
        padding-right: 60px;
    }
`;

const StavkroaArena = ({ slug, content }) => {
    useSlug('venue/' + slug);

    if (!content) return null;

    return (
        <Container>
            <ModuleList modules={content.modules} />
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            content: state.pages['venue/' + ownProps.slug]
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(StavkroaArena);
