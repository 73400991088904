import React, { useEffect } from 'react';
import { useRoutes } from 'hookrouter';
import Page from './templates/Page';
import Event from './templates/Event';
import Venue from './templates/Venue';
import StavkroaArena from './templates/StavkroaArena';

const routes = {
    '/': () => <Page slug="/" />,
    '/event/:slug': ({ slug }) => <Event slug={slug} />,
    '/venue/stavkroa-arena': () => <StavkroaArena slug="stavkroa-arena" />,
    '/venue/:slug': ({ slug }) => <Venue slug={slug} />,
    '/:slug': ({ slug }) => <Page slug={slug} />
};

const Routes = () => {
    const hookedRoutes = useRoutes(routes);
    useEffect(() => window.scrollTo(0, 0));
    return hookedRoutes || 'Not Found';
};

export default Routes;
