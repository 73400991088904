import React from 'react';
import { useInView } from 'react-hook-inview';
import { ReactFitty } from 'react-fitty';

import styled, { css } from 'styled-components';
import { colors } from '../../const';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% + 40px);
  transform: translateY(150px);
  @media (min-width: 768px) {
      width: calc(100% + 120px);
    }

  ${props =>
      props.isVisible &&
      `
    transform: translateY(0);
      transition: transform 0.5s ease-out;
  `}

  div {
    margin-left: -20px;
    margin-right: -20px;
    text-align: center;
    @media (min-width: 768px) {
      margin-left: -60px;
      margin-right: -60px;
    }
    font-weight: 600;
      ${props =>
          props.isFilled
              ? props.color === 'gold'
                  ? css`
                        color: ${colors.neonGold};
                    `
                  : css`
                        color: ${colors.pink};
                    `
              : css`
                    color: transparent;
                `}
      ${props =>
          props.color === 'gold' &&
          css`
              text-stroke: 1.5px ${colors.neonGold};
              -webkit-text-stroke: 1.5px ${colors.neonGold};
          `}
      ${props =>
          props.color === 'pink' &&
          css`
              text-stroke: 1.5px ${colors.pink};
              -webkit-text-stroke: 1.5px ${colors.pink};
          `}

      @media (min-width: 768px) {
        ${props =>
            props.color === 'gold' &&
            css`
                text-stroke: 1.5px ${colors.neonGold};
                -webkit-text-stroke: 1.5px ${colors.neonGold};
            `}
        ${props =>
            props.color === 'pink' &&
            css`
                text-stroke: 1.5px ${colors.pink};
                -webkit-text-stroke: 1.5px ${colors.pink};
            `}
      }
  }`;

const PageTitle = ({ title, color, isFilled, sectionId }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            color={color}
            isVisible={isVisible}
            isFilled={isFilled}
            ref={ref}
            id={sectionId}
        >
            <ReactFitty>{title}</ReactFitty>
        </Container>
    );
};

export default PageTitle;
