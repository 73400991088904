import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { colors } from '../../const';
import emailjs from '@emailjs/browser';
import { useInView } from 'react-hook-inview';
import { List } from './FactsList';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    label {
        width: calc(50% - 1em);
    }

    @media (max-width: 768px) {
        display: block;
        width: 100%;

        label {
            width: 100%;
        }
    }
`;

const Label = styled.label`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;

    @media (min-width: 768px) {
        margin-bottom: 0.5em;
    }
`;

const Button = styled.button`
    all: unset;
    outline: revert;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    transition: background-color 0.25s ease;

    background-color: ${props => (props.disabled ? 'grey' : colors.pink)};

    &:hover {
        background-color: ${props =>
            props.disabled ? 'grey' : colors.neonPink};
    }

    &:active {
        background-color: ${props =>
            props.disabled ? 'grey' : colors.darkPink};
    }

    color: white;
    padding: 0.3em 0.6em;
    font-weight: bold;
    font-size: 1.5em;
`;

const SuccessMessage = styled.span`
    margin-top: 1em;
    display: block;
`;

const Form = styled.form`
    width: 100%;
    grid-column-start: 7;
    grid-column-end: 13;
    grid-row-start: 1;

    ${props =>
        props.order === 'right'
            ? css`
                  grid-column-start: 1;
                  grid-column-end: 7;
              `
            : css`
                  grid-column-start: 7;
                  grid-column-end: 13;
              `};

    input,
    textarea {
        padding: 8px 12px;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 24px;
    margin-top: 2em;
    width: 100%;
    transform: translateY(150px);

    ${props =>
        props.isVisible &&
        `
        transform: translateY(0);
        transition: transform 0.5s ease-out;
      `}

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;

const ContactForm = ({ module }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });

    const { type, facts } = module;
    const { register, handleSubmit, watch } = useForm();

    const onSubmit = async data => {
        console.log('formdata:', data);

        emailjs
            .send(
                'service_bwlymxc',
                'template_x5elo07',
                data,
                'CYaPqeZHgNb0ohGqs'
            )
            .then(() => setIsSubmitted(true));
    };

    const senderEmail = watch('email');
    return (
        <Grid ref={ref} isVisible={isVisible}>
            <FormProvider>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    type={type}
                    order={facts?.order}
                >
                    <Container>
                        <Label>
                            First name
                            <input {...register('firstName')} required />
                        </Label>
                        <Label>
                            Last name
                            <input {...register('lastName')} required />
                        </Label>
                        {type === 'business' && (
                            <>
                                <Label>
                                    Company name
                                    <input {...register('companyName')} />
                                </Label>
                                <Label>
                                    Org. number
                                    <input
                                        {...register('organizationNumber')}
                                    />
                                </Label>
                            </>
                        )}

                        <Label>
                            Email address
                            <input {...register('email')} required />
                        </Label>
                        <Label>
                            Phone
                            <input {...register('phone')} />
                        </Label>
                        <Label>
                            Date
                            <input {...register('date')} type="date" required />
                        </Label>
                        <Label>
                            Number of guests
                            <input
                                {...register('numberOfGuests')}
                                type="number"
                                min={0}
                                required
                            />
                        </Label>

                        <Label style={{ marginBottom: '2em' }}>
                            Tell us a bit about your event
                            <textarea {...register('comment')} rows={8} />
                        </Label>
                    </Container>
                    <Button disabled={isSubmitted}>Submit</Button>
                    {isSubmitted && (
                        <SuccessMessage>{`Success! A request from ${senderEmail} has been sent, and will be replied as soon as possible!`}</SuccessMessage>
                    )}
                </Form>
            </FormProvider>
            {facts && <List module={facts} className="contact" />}
        </Grid>
    );
};

export default ContactForm;
