import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../const';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 24px;
    width: 100%;

    transform: translateY(150px);

    ${props =>
        props.isVisible &&
        `
      transform: translateY(0);
        transition: transform 0.5s ease-out;
      `}
`;

const Title = styled.h3`
    font-size: 2em;
    color: ${colors.neonGold};
    margin-bottom: 0;
    margin-block-start: 0;

    ${props =>
        props.color === 'pink' &&
        css`
            color: ${colors.pink};
        `};
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;

    &.fact-list {
        grid-column-start: 1;
        grid-column-end: 13;

        @media (min-width: 768px) {
            ${props =>
                props.order === 'right' &&
                css`
                    grid-column-start: 9;
                `};
        }
    }
    &.contact {
        grid-column-start: 1;
        grid-column-end: 13;
        grid-row-start: 1;

        @media (min-width: 768px) {
            ${props =>
                props.order === 'right'
                    ? css`
                          grid-column-start: 8;
                      `
                    : css`
                          grid-column-start: 1;
                          grid-column-end: 6;
                      `};
        }
    }
`;

const ListItem = styled.div``;

const Description = styled.p`
    margin: 0 0 1em;
`;

export const List = ({ module, className }) => {
    const { facts, order, color } = module;
    return (
        <StyledDiv order={order} className={className}>
            {facts?.map(i => (
                <ListItem>
                    <Title color={color}>{i.title}</Title>
                    <Description>{i.description}</Description>
                </ListItem>
            ))}
        </StyledDiv>
    );
};

const FactsList = ({ module }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });

    return (
        <Container isVisible={isVisible} ref={ref}>
            <List module={module} className="fact-list" />
        </Container>
    );
};

export default FactsList;
