import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../const';
import { useInView } from 'react-hook-inview';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 24px;
    width: 100%;
    transform: translateY(150px);

    ${props =>
        props.isVisible &&
        `
      transform: translateY(0);
        transition: transform 0.5s ease-out;
      `}

    img,
    iframe {
        grid-column-start: 1;
        grid-column-end: 13;
        ${props =>
            props.orientation === 'mediaLeft' &&
            css`
                @media (min-width: 768px) {
                    grid-column-start: 1;
                    grid-column-end: 7;
                }
            `}
        ${props =>
            props.orientation === 'mediaRight' &&
            css`
                @media (min-width: 768px) {
                    grid-column-start: 7;
                    grid-column-end: 13;
                }
            `}
    }

    h2 {
        font-size: 48px;
        line-height: 1;
        margin: 0;
        ${props =>
            props.color === 'gold' &&
            css`
                text-stroke: 1.5px ${colors.neonGold};
                -webkit-text-stroke: 1.5px ${colors.neonGold};
            `}
        ${props =>
            props.color === 'pink' &&
            css`
                text-stroke: 1.5px ${colors.pink};
                -webkit-text-stroke: 1.5px ${colors.pink};
            `}

        color: transparent;
        grid-column-start: 1;
        grid-column-end: 13;
        ${props =>
            props.orientation === 'mediaLeft' &&
            css`
                @media (min-width: 768px) {
                    grid-column-start: 9;
                    grid-column-end: 13;
                    transform: translateX(100%);
                    rotate: 90deg;
                    transform-origin: top right;
                }
            `}
        ${props =>
            props.orientation === 'mediaRight' &&
            css`
                @media (min-width: 768px) {
                    grid-column-start: 3;
                    grid-column-end: 7;
                    rotate: -90deg;
                    transform: translateX(0%);
                    transform-origin: top left;
                }
            `}

        @media (min-width: 768px) {
            font-size: 114px;
            ${props =>
                props.color === 'gold' &&
                css`
                    text-stroke: 1.5px ${colors.neonGold};
                    -webkit-text-stroke: 1.5px ${colors.neonGold};
                `}
            ${props =>
                props.color === 'pink' &&
                css`
                    text-stroke: 1.5px ${colors.pink};
                    -webkit-text-stroke: 1.5px ${colors.pink};
                `}
        }
    }
`;

const CalloutWithMedia = ({
    orientation,
    media_image,
    title,
    color,
    video
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const renderMedia = () => {
        if (media_image) {
            return <img src={media_image && media_image} />;
        }
        if (video) {
            return (
                <iframe
                    src={video.name + '?background=1'}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                ></iframe>
            );
        }
    };
    return (
        <Grid
            orientation={orientation}
            color={color}
            isVisible={isVisible}
            ref={ref}
        >
            {renderMedia()}
            <h2>{title}</h2>
        </Grid>
    );
};

export default CalloutWithMedia;
