import React from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';
import { colors } from '../../const';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    transform: translateY(150px);

    ${props =>
        props.isVisible &&
        `
      transform: translateY(0);
        transition: transform 0.5s ease-out;
      `}

    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;

    @media (min-width: 768px) {
        margin-left: -60px;
        margin-right: -60px;
    }

    img {
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .content {
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 3;
        justify-self: center;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .byline {
            padding: 8px;
            background-color: ${colors.neonGold};
            font-size: 24px;
            font-weight: 500;

            @media (min-width: 768px) {
                padding: 12px;
                font-size: 48px;
            }
        }
        .ticker {
            padding: 8px;
            background-color: ${colors.pink};
            font-weight: 500;
            font-size: 36px;

            @media (min-width: 768px) {
                padding: 12px;
                font-size: 96px;
            }
        }
    }

    h1 {
        line-height: 1;
        margin: 0;
        text-stroke: 1.5px ${colors.neonGold};
        -webkit-text-stroke: 1.5px ${colors.neonGold};
        grid-row-start: 3;
        color: transparent;
        font-size: 10.85vw; //lol
        @media (min-width: 768px) {
            text-stroke: 3px ${colors.neonGold};
            -webkit-text-stroke: 3px ${colors.neonGold};
        }
    }
`;

const PageHeroTakeOver = ({
    media_image,
    video,
    byline,
    title,
    countdownTime
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const renderMedia = () => {
        if (media_image) {
            return <img src={media_image && media_image} alt="" />;
        }
        if (video) {
            return (
                <iframe
                    src={video.name + '?background=1'}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title="Video"
                ></iframe>
            );
        }
    };
    return (
        <Container
            isVisible={isVisible}
            className={isVisible ? 'in-view ' : ''}
            ref={ref}
        >
            {renderMedia()}
            <div className="content">
                {byline && <span className="byline">{byline}</span>}
                {countdownTime && (
                    <Countdown className="ticker" date={countdownTime} />
                )}
            </div>
            {title && <h1>{title}</h1>}
        </Container>
    );
};

export default PageHeroTakeOver;
