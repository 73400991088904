export const colors = {
    pink: '#FF007D',
    darkPink: '#bd045e',
    neonPink: '#ff005f',
    purple: '#210010',
    gold: '#836133',
    neonGold: '#cdb16e', //kek
    grey: '#333333',
    greyBorder: '#707070'
};
