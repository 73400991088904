import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../const';
import getFullSlug from '../../utils/getFullSlug';
import { useInView } from 'react-hook-inview';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 24px;
    width: 100%;
    transform: translateY(150px);

    ${props =>
        props.isVisible &&
        `
      transform: translateY(0);
        transition: transform 0.5s ease-out;
      `}

    div {
        grid-column-start: 1;
        grid-column-end: 13;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        margin: 0 auto;

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }
`;

const StyledA = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    ${props => {
        if (props.buttonColor === 'purple') {
            return css`
                background-color: ${colors.purple};
            `;
        }
        if (props.buttonColor === 'gold') {
            return css`
                background-color: ${colors.neonGold};
            `;
        }
        if (props.buttonColor === 'pink') {
            return css`
                background-color: ${colors.pink};
            `;
        }
    }};
    color: white;
    padding: 24px 48px;
    font-weight: bold;
    font-size: 36px;
    @media (min-width: 768px) {
        font-size: 64px;
        ${props =>
            !props.isSingleButton &&
            css`
                width: 50%;
            `}
    }
`;

const CTAButton = ({ buttons }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Grid isVisible={isVisible} ref={ref}>
            <div>
                {buttons.map(button => {
                    const isExternal =
                        button.linkObj.externalLink?.url.length > 0;
                    const url = isExternal
                        ? button.linkObj.externalLink
                        : button.linkObj.internalLink;
                    return (
                        <StyledA
                            href={getFullSlug(url)}
                            buttonColor={button.buttonColor}
                            isSingleButton={buttons.length === 1}
                        >
                            <span>{button.linkObj.title}</span>
                        </StyledA>
                    );
                })}
            </div>
        </Grid>
    );
};

export default CTAButton;
