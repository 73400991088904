import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-hook-inview';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 24px;
    width: 100%;
    transform: translateY(150px);

    ${props =>
        props.isVisible &&
        `
        transform: translateY(0);
        transition: transform 0.5s ease-out;
      `}

    img,
    iframe {
        grid-column-start: 1;
        grid-column-end: 13;
        @media (min-width: 768px) {
            ${props => props.gridPosition && props.gridPosition}
        }
    }
`;

const MediaWithSizeOption = ({ media_image, video, size, offset }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const getGridPosition = () => {
        let total = size + offset;
        if (total < 12) {
            return `grid-column-start: ${offset}; grid-column-end: ${offset +
                size};`;
        } else {
            return `grid-column-start: ${offset}; grid-column-end: -1;`;
        }
    };
    const renderMedia = () => {
        if (media_image) {
            return <img src={media_image && media_image} alt="" />;
        }
        if (video) {
            return (
                <iframe
                    src={video.name + '?background=1'}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title="Video"
                ></iframe>
            );
        }
    };
    return (
        <Grid gridPosition={getGridPosition()} ref={ref} isVisible={isVisible}>
            {renderMedia()}
        </Grid>
    );
};

export default MediaWithSizeOption;
