import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../const';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 100px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        flex-direction: column-reverse !important;
        margin-bottom: 50px;

        .column {
            width: 100% !important;
        }

        .column.image .image-wrap img {
            position: relative;
        }
    }

    ${props =>
        props.reversed &&
        css`
            .text-box {
                left: -50%;
            }
            flex-direction: row-reverse;

            @media (max-width: 768px) {
                flex-direction: column-reverse !important;

                .text-box {
                    left: auto;
                }
            }
        `}

    .text-box {
        padding: 60px 50px;

        ${props => {
            if (props.backgroundColor === 'purple') {
                return css`
                    background-color: ${colors.purple};
                `;
            }
            if (props.backgroundColor === 'gold') {
                return css`
                    background-color: ${colors.neonGold};
                `;
            }
            if (props.backgroundColor === 'pink') {
                return css`
                    background-color: ${colors.pink};
                `;
            }
        }};

        position: absolute;
        width: 150%;

        @media (max-width: 768px) {
            width: 100%;
            position: relative;
            padding: 20px 20px 40px;
        }

        h1,
        h2 {
            font-size: 36px;
            font-weight: 700;

            @media (max-width: 768px) {
                font-size: 22px;
            }
        }
        p {
            font-size: 30px;
            font-weight: 500;
            @media (max-width: 1280px) {
                font-size: 22px;
            }
            @media (max-width: 768px) {
                font-size: 16px;
            }
        }
    }

    .column.text {
        width: 30%;
        position: relative;
        display: flex;
        z-index: 2;

        &.middle {
            align-items: center;
        }

        &.bottom {
            align-items: flex-end;
        }

        &.top {
            align-items: flex-start;
        }
    }

    .column.image {
        width: 70%;
        .image-wrap {
            position: relative;
            :after {
                content: ' ';
                display: block;
                padding-bottom: 56.25%;
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
                object-position: center center;
            }

            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
`;

const ImageAndTextBox = ({
    media_image,
    video,
    text,
    reversed,
    textPosition,
    noTransition,
    backgroundColor = 'purple',
    sectionId
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });

    const renderMedia = () => {
        if (media_image) {
            return <img src={media_image && media_image} />;
        }
        if (video) {
            return (
                <iframe
                    src={video.name + '?background=1'}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                ></iframe>
            );
        }
    };

    return (
        <Container
            className={
                'module module-image-and-text-box ' +
                (isVisible ? 'in-view ' : '') +
                (noTransition ? 'no-transition ' : '')
            }
            reversed={reversed}
            ref={ref}
            backgroundColor={backgroundColor}
            id={sectionId}
        >
            <div
                className={
                    'column text ' + (textPosition ? textPosition : 'middle')
                }
            >
                <div className="text-box">{text}</div>
            </div>

            <div className="column image">
                <div className="image-wrap">{renderMedia()}</div>
            </div>
        </Container>
    );
};

export default ImageAndTextBox;
