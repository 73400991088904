import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ModuleList from '../components/modules/ModuleList';
import useSlug from '../hooks/useSlug';
import stripSlug from '../utils/stripSlug';

const Container = styled.div`
    padding-left: 60px;
    padding-right: 60px;
    width: 100%;
    padding-top: 84px;

    @media (max-width: 768px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
    }

    &:after {
        content: '';
        clear: both;
        display: table;
    }

    > .module {
        margin-bottom: 80px;
    }
`;

const Page = ({ slug, content }) => {
    useSlug(slug);

    if (!content) return null;

    return (
        <Container>
            <ModuleList modules={content.modules} />
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            content: state.pages[stripSlug(ownProps.slug)]
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(Page);
